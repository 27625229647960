<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">课程使用统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">查看班级</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="班级信息" class="searchboxItem ci-full">
              <span class="itemLabel">班级信息:</span>
              <el-select v-model="searchData.projectId" placeholder="请选择" remote size="small" clearable :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      ">班级编号</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      ">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="searchData_Project.seaProjectCode" v-on:input="superProjectSelect" type="text" size="small" placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="searchData_Project.seaProjectName" v-on:input="superProjectSelect" type="text" size="small" placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="item in searchData_Project.projectList" :key="item.projectId" :label="item.projectName" :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="开班机构" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">开班机构:</span>
              <el-select size="small" v-model="searchData.compId" remote :remote-method="getCompanyList" filterable clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName" :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }" v-model="searchData.trainTypeId" size="small" clearable :options="trainTypeList" @change="handleTrainType"></el-cascader>
            </div>
            <div class="df" style="padding-right: 45px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="getExport()">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip min-width="180" prop="projectCode" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip min-width="180" prop="projectName" />
              <el-table-column label="培训类型" align="left" show-overflow-tooltip min-width="200" prop="categoryName" />
              <el-table-column label="开班机构" align="left" show-overflow-tooltip min-width="180" prop="compName" />
              <el-table-column label="培训有效期" align="left" show-overflow-tooltip min-width="180" prop="courseName" >
                <template slot-scope="{row}">
                  {{ row.startDate }}~{{ row.endDate }}
                </template>
              </el-table-column>
              <el-table-column label="入班人数" align="left" show-overflow-tooltip min-width="100" prop="joinCount" />
              <el-table-column label="结业人数" align="left" show-overflow-tooltip min-width="100" prop="graduation" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "set/TrajectoryList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit:true,
  data () {
    return {
      courseId:'',
      companyList: [],
      // 检索的数据
      searchData: {
        projectId:'',
        compId:'',
        trainTypeId:'',
      },
      // 班级信息 - 组件查询内容
      searchData_Project: {
        seaProjectCode: "", // 班级编码
        seaProjectName: "", // 班级名称
        projectList: [], // 班级列表
      },
      // 培训类型 - 下拉数据
      trainTypeList: [],
    };
  },
  created () {
    this.courseId = this.$route.query.courseId
    this.getTraintype()
    this.getData()
  },
  watch: {},
  computed: {
  },
  mounted () { },
  methods: {
    // 获取 - 班级信息
    superProjectSelect (e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1,
          pageSize: 5,
          projectName: this.searchData_Project.seaProjectName,
          projectCode: this.searchData_Project.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length > 0) {
          this.searchData_Project.projectList = res.data.list;
        } else {
          this.searchData_Project.projectList = [];
        }
      });
    },
    // 获取 - 班级信息 - 清空
    clearProjectSearchModel (e) {
      if (e) {
        this.searchData_Project.seaProjectName = "";
        this.searchData_Project.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    // 获取 - 所属机构
    getCompanyList (query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/getCompanyInfoByName", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 获取培训类型 - 下拉数据
    getTraintype () {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 获取列表数据
    getData (pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseId:this.courseId,
      };
      if (this.searchData.projectId) {
        params.projectId = this.searchData.projectId;
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      this.doFetch({
        url: "/biz/course/usage/statisticsProject",
        params,
        pageNum,
      });
    },

    getExport () {
      const params = {
        courseId:this.courseId,
      };
      if (this.searchData.projectId) {
        params.projectId = this.searchData.projectId;
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      this.$post("/biz/course/usage/statisticsProject/export", params).then((res) => {
        if (res.status == "0") {
         window.open(res.data)
        } 
      });
    },
  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
