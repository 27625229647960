import { render, staticRenderFns } from "./courseUsageStatisticsInfo.vue?vue&type=template&id=3630c1ea&scoped=true"
import script from "./courseUsageStatisticsInfo.vue?vue&type=script&lang=js"
export * from "./courseUsageStatisticsInfo.vue?vue&type=script&lang=js"
import style0 from "./courseUsageStatisticsInfo.vue?vue&type=style&index=0&id=3630c1ea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3630c1ea",
  null
  
)

export default component.exports